<template>
  <div id="negociated-rates">
    <!-- IMPORT -->
    <b-card v-show="showImport" no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('service.fuel.prices.automatic_importation') }}</h5>
      </b-card-header>
    </b-card>
    <!-- Table Container Card -->
    <b-card class="mb-0" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col class="d-flex align-items-center justify-content-start mb-1 mb-lg-0" cols="12" lg="1">
            <b-form-select
              v-model="numberOfItemsPerPage"
              :disabled="negotiatedRatesLoading"
              :options="dataTable.parPageOption"
              class="per-page-selector"
              @change="fetchNegotiatedRates(true)"
            />
          </b-col>
          <b-col class="d-flex align-items-center justify-content-start mb-1 mb-lg-0" cols="12" lg="5">
            <b-input-group>
              <b-input-group-prepend>
                <b-form-select v-model="searchField" :disabled="negotiatedRatesLoading" class="select-search">
                  <b-form-select-option v-for="(option, index) in searchFieldOptions" :key="index" :value="option">
                    <!-- <span v-if="option === 'all'"> All</span> -->
                    <span v-if="option === 'companyName'"> {{ $t('negotiated_rate.company_name') }}</span>
                    <span v-if="option === 'intracommunityVatNumber'">
                      {{ $t('negotiated_rate.intra_community_vat') }}
                    </span>
                  </b-form-select-option>
                </b-form-select>
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                :disabled="negotiatedRatesLoading"
                :placeholder="$t('action.search')"
                @keydown.enter="fetchNegotiatedRates(true)"
                @keyup.backspace="() => search === '' && fetchNegotiatedRates(true)"
              />
              <b-input-group-append>
                <b-button id="negotiated-rate-search" :disabled="searchText === '' || negotiatedRatesLoading" size="sm" @click="fetchNegotiatedRates(true)">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <!-- Search and action -->
          <b-col class="d-flex align-items-center justify-content-end" cols="12" lg="6">
            <div class="d-flex align-items-center justify-content-end">
              <!--<b-button
                id="negotiated-rate-import"
                variant="outline-primary"
                :disabled="!$can('NEGOTIATED_RATE_ADD') || !$can('NEGOTIATED_RATE_EDIT') || negotiatedRatesLoading"
                class="mr-1"
                @click="() => (showImport = !showImport)"
              >
                <span class="text-nowrap d-none d-lg-block">
                  <feather-icon icon="DownloadIcon" class="mr-50" />
                  {{ $t('action.import') }}
                </span>
                <feather-icon icon="DownloadIcon" class="d-lg-none" />
              </b-button>-->
              <b-button id="negotiated-rate-add" :disabled="!$can('NEGOTIATED_RATE_ADD') || negotiatedRatesLoading" @click="negotiatedRate(null, 'add')">
                <span class="text-nowrap d-none d-lg-block">
                  <feather-icon class="mr-50" icon="PlusIcon" />
                  {{ $t('action.add') }}
                </span>
                <feather-icon class="d-lg-none" icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- TABLE -->
      <b-table
        ref="negociated-rates-table"
        :busy="negotiatedRatesLoading"
        :empty-text="$t('common.no_record_found')"
        :fields="negotiatedRatesFields"
        :items="negotiatedRates"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        class="position-relative table-dropdown-action"
        hover
        no-local-sorting
        no-sort-reset
        primary-key="id"
        responsive
        show-empty
        @sort-changed="updateSort"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- HEAD -->
        <template #head(companyName)>
          {{ $t('negotiated_rate.company_name') }}
        </template>
        <template #head(intracommunityVatNumber)>
          {{ $t('negotiated_rate.intra_community_vat') }}
        </template>
        <template #head(cancellationPolicy)>
          {{ $tc('cancellation_policy.title', 1) }}
        </template>
        <template #head(priceManagement)>
          {{ $tc('price_management.title', 1) }}
        </template>
        <!-- CELL -->
        <template #cell(companyName)="data">
          <b-link :disabled="!$can('NEGOTIATED_RATE_EDIT')" class="font-weight-bold d-block text-nowrap" @click="negotiatedRate(data.item.id, 'edit')">
            {{ data.item.companyName }}
          </b-link>
        </template>
        <template #cell(cancellationPolicy)="data">
          <b-link
            v-if="data.item.cancellationPolicy"
            :disabled="!$can('CANCELLATION_POLICY_EDIT')"
            :to="{ name: 'cancellationPolicyEdit', params: { id: data.item.cancellationPolicy.id } }"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            {{ data.item.cancellationPolicy.name }}
          </b-link>
        </template>
        <template #cell(priceManagement)="data">
          <b-link
            v-if="data.item.priceManagement"
            :disabled="!$can('PRICE_MANAGEMENT_EDIT')"
            :to="{ name: 'priceManagementEdit', params: { id: data.item.priceManagement.id } }"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            {{ data.item.priceManagement.name }}
          </b-link>
          <span v-else class="condition-for-rule">Custom</span>
          <!-- TODO if custom -->
        </template>
        <!--status-->
        <template #cell(status)="data">
          <b-badge v-if="data.item.status === 'active'" pill variant="light-success">
            {{ data.item.status|enumTranslate('negotiated_rate_status') }}
          </b-badge>
          <b-badge v-if="data.item.status === 'inactive'" pill variant="light-danger">
            {{ data.item.status|enumTranslate('negotiated_rate_status') }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <app-data-table-actions
            :show-delete="$can('NEGOTIATED_RATE_DELETE')"
            :show-duplicate="$can('NEGOTIATED_RATE_ADD')"
            :show-edit="$can('NEGOTIATED_RATE_EDIT')"
            :show-show="$can('NEGOTIATED_RATE_VIEW')"
            table-name="negociated-rates-table"
            @delete="deleteNegotiatedRate(data.item)"
            @duplicate="negotiatedRate(data.item.id, 'duplicate')"
            @edit="negotiatedRate(data.item.id, 'edit')"
            @show="negotiatedRate(data.item.id, 'show')"
          />
        </template>
      </b-table>
      <!--PAGINATION-->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Items -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <app-data-table-entries-display
              :items-length="negotiatedRates.length"
              :page="page"
              :per-page="numberOfItemsPerPage"
              :total-count="pagination.totalItems"
            />
          </b-col>
          <!-- Pagination -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
            <b-pagination
              v-model="page"
              :per-page="numberOfItemsPerPage"
              :total-rows="pagination.totalItems"
              align="end"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @change="fetchNegotiatedRates(true)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <NegotiatedRate
      :negotiated-rate-edition-mode.sync="negotiatedRateEditionMode"
      :negotiated-rate-edition.sync="negotiatedRateEdition"
      :negotiated-rate-id.sync="selectedNegotiatedRateId"
      :negotiated-rate-id="selectedNegotiatedRateId"
      :negotiated-rates-loading="negotiatedRatesLoading"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import AppDropZone from '@/components/AppDropZone.vue'
import AppDataTableEntriesDisplay from '@/components/AppDataTableEntriesDisplay.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'

import { deleteNegotiatedRateRequest } from '@/request/globalApi/requests/negotiatedRateRequests'

import NegotiatedRate from './NegotiatedRate.vue'

export default {
  name: 'NegotiatedRates',

  components: {
    NegotiatedRate,
    AppDropZone,
    AppDataTableEntriesDisplay,
    AppDataTableActions,
  },

  data() {
    return {
      showImport: false,
      negotiatedRateEdition: false,
      negotiatedRateEditionMode: '',
      selectedNegotiatedRateId: null,
      negotiatedRatesFields: [
        { key: 'companyName', sortable: true },
        { key: 'intracommunityVatNumber', sortable: true },
        { key: 'cancellationPolicy' },
        { key: 'priceManagement' },
        { key: 'status' },
        { key: 'actions', thClass: 'text-right', tdClass: 'text-right' },
      ],

      dropZoneLoading: false,
      dropzoneOptions: {
        autoProcessQueue: true,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('cancellation_policy.condition.upload'),
        thumbnailWidth: 250,
        maxFiles: 1,
        acceptedFiles: '',
        addRemoveLinks: true,
        clickable: true,
      },
    }
  },

  computed: {
    ...mapState('negotiatedRate', ['negotiatedRatesLoading', 'negotiatedRates', 'pagination', 'searchText']),
    ...mapState('appConfig', ['dataTable']),
    ...mapGetters('negotiatedRate', ['searchFieldOptions']),

    ...mapFields('negotiatedRate', ['paginate.page', 'paginate.numberOfItemsPerPage', 'dataTableSort.sortBy', 'dataTableSort.sortDesc', 'searchField']),
    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },

  methods: {
    ...mapActions('negotiatedRate', ['fetchNegotiatedRates', 'clearNegotiatedRatesShowed', 'setSort', 'setSearch']),

    deleteNegotiatedRate(negotiatedRate) {
      deleteNegotiatedRateRequest(negotiatedRate.id, negotiatedRate.companyName).then(response => {
        response && this.fetchNegotiatedRates(true)
      })
    },

    negotiatedRate(id, mode) {
      this.selectedNegotiatedRateId = id
      this.negotiatedRateEditionMode = mode
      this.negotiatedRateEdition = true
    },

    updateSort(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.setSort({ sortBy: e.sortBy, sortDesc: e.sortDesc })
    },
  },

  mounted() {
    this.fetchNegotiatedRates()
  },

  beforeRouteLeave(to, from, next) {
    this.clearNegotiatedRatesShowed()
    next()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.condition-for-rule {
  color: #07d0e8;
}
.select-search {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
</style>
